const MeetTeam = () => {
    // const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

    return ( 
        <div className="coreteam">
            <h1 className="meetteam">Meet Our Core Team</h1>
            <h4 className="meetteam2">The Core team here at TEDxNITGOA is made up of a diverse bunch of awesome humans!</h4>
            <p>We are driven by passion and curiosity. We are inspired by ideas. And we want to leave the world better than we found it. In our day lives you can find us working in design, computer science, chemistry, biology, finance, NGOs, arts, business, psycology, health and everything in between. We work year round to bring TEDxNITGOA and all of our activities to life.</p>
            <div className="aboutusteam">
            <a href="" class="TeamMember_link__WlwN3">
                <div className="TeamMember_imgBox__xMdN4" target="blank" style={{position: 'relative'}}>
                    <img src="/organizer.jpg" className="ocpic" alt="Img"/>
                </div>
                <div className="TeamMember_imgTitle__zaUW+">
                    <div className="TeamMember_imgTitleName__g1Dgw">Nisarg shah</div>
                    <div className="TeamMember_imgTitlePost__n4O8U">Licensee and Curator</div>
                </div>
            </a>
            <a href="" class="TeamMember_link__WlwN3">
                <div className="TeamMember_imgBox__xMdN4" target="blank" style={{position: 'relative'}}>
                    <img src="/oc.png" alt="Img"/>
                </div>
                <div className="TeamMember_imgTitle__zaUW+">
                    <div className="TeamMember_imgTitleName__g1Dgw">jeednya</div>
                    <div className="TeamMember_imgTitlePost__n4O8U">PR Head</div>
                </div>
            </a>
            <a href="" class="TeamMember_link__WlwN3">
                <div className="TeamMember_imgBox__xMdN4" target="blank" style={{position: 'relative'}}>
                    <img src="/oc.png" alt="Img"/>
                </div>
                <div className="TeamMember_imgTitle__zaUW+">
                    <div className="TeamMember_imgTitleName__g1Dgw">Nisarg shah</div>
                    <div className="TeamMember_imgTitlePost__n4O8U">Organizers</div>
                </div>
            </a>
            </div>

        </div>
     );
}
 
export default MeetTeam;

